
/**
 ** Represents array of animated green stars images.
 ** Each element contains values for animated image of green star:  [top position, left position, 
    animation delay, animation duration] and represents props for BaseStar component.
 */
export const stars = [
    ["3%", "15%", "0.3s", "2s"], ["5%", "9%", "0.1s", "4s"], ["7%", "5%", "0.8s", "3s"], ["10%", "12%", "0.2s", "3s"],
    ["11%", "0%", "0.3s", "2s"], ["13%", "10%", "0.1s", "4s"], ["17%", "5%", "0.8s", "3s"], ["19%", "8%", "0.2s", "3s"],
    ["20%", "9%", "0.3s", "2s"], ["23%", "20%", "0.1s", "4s"], ["24%", "20%", "0.8s", "3s"], ["29%", "12%", "0.2s", "3s"],
    ["31%", "3%", "0.3s", "2s"], ["34%", "10%", "0.1s", "4s"], ["37%", "2%", "0.8s", "3s"], ["39%", "0%", "0.2s", "3s"],
    ["42%", "0%", "0.3s", "2s"], ["45%", "25%", "0.1s", "4s"], ["48%", "8%", "0.8s", "3s"], ["50%", "15%", "0.2s", "3s"],
    ["53%", "0%", "0.3s", "2s"], ["55%", "3%", "0.1s", "4s"], ["57%", "20%", "0.8s", "3s"], ["60%", "8%", "0.2s", "3s"],
    ["62%", "15%", "0.3s", "2s"], ["64%", "9%", "0.1s", "4s"], ["66%", "2%", "0.8s", "3s"], ["68%", "12%", "0.2s", "3s"],
    ["70%", "9%", "0.3s", "2s"], ["73%", "20%", "0.1s", "4s"], ["75%", "0%", "0.8s", "3s"], ["79%", "8%", "0.2s", "3s"],
    ["81%", "0%", "0.3s", "2s"], ["84%", "10%", "0.1s", "4s"], ["86%", "20%", "0.8s", "3s"], ["90%", "10%", "0.2s", "3s"],
]