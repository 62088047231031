import { whiteWashedSpaceGreen } from "../../constants/Colors";

const styles = {
    wrapper: {
        padding: "0 5%",
    },
    contact: {
        display:"flex", 
        flexDirection:"row",
        justifyContent:"space-between",
        marginBottom: "2.5%"
    },
    contactMobile: {
        display:"flex", 
        flexDirection:"column",
        justifyContent:"space-between",
        marginBottom: "2.5%"
    },
    social: {
        alignSelf:"flex-end",
        width:"20%",
    },
    socialMobile: {
        width:"90%",
        marginBottom:"15vh"
    },
    tile: {
        width:"35%", 
        marginBottom:"10%",
        marginTop:"3%"
    },
    tileMobile: {
        width:"100%", 
        marginBottom:"10%"
    },
    linksSocial: {
        display:"flex", 
        flexDirection:"row",
        // PUT THIS BACK IN WHEN YOU UNCOMMENT LINKEDIN justifyContent: "space-between"
    },
    info: {
        textAlign:"end", 
        display:"flex", 
        flexDirection:"column",
        alignItems:"flex-end"
    },
    time: {
        color: whiteWashedSpaceGreen,
        fontSize:"4em"
    },
    timeMobile: {
        color: whiteWashedSpaceGreen,
        fontSize:"2em",
        marginBottom:"2%"
    },
    address: {
        width:"50%",  
        alignSelf:"flex-end"
    },
    endSection: {
        display:"flex", 
        flexDirection:"row", 
        justifyContent:"space-between", 
        margin:"2.5% 0% 3%"
    },
    line: {
        width:"100vw", 
        marginLeft:"-5vw", 
        height:"2px", 
        background:"rgba(255, 255, 255, 0.1)"
    },
    lineMobile: {
        width:"110%", 
        marginLeft:"-5%", 
        height:"2px", 
        background:"rgba(255, 255, 255, 0.1)"
    },
    removeDecorationLink: {
        textDecoration: "none",
        color: "#fff",
    }
}

export default styles;