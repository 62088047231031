const styles = {
    wrapper: {
        marginTop:"7%", 
    },
    wrapperMobile: {
        marginTop:"7vh", 
    },
    text:{
        padding:"5% 0"
    },
    row: {
        display:"flex", 
        flexDirection:"row"
    },
    title: {
        width:"40%", 
        marginRight:"10%", 
        fontSize:"4em"
    },
    titleMobile: {
        width:"100%", 
        fontSize:"2em"
    },
    description: {
        width:"35%", 
        fontSize:"1em"
    },
    descriptionMobile: {
        width:"100%", 
        fontSize:"1em"
    },
    descriptionAside: {
        width:"70%", 
        fontSize:"1em"
    },
    descriptionAsideMobile: {
        width:"100%", 
        fontSize:"1em"
    },
    line:{
        width:"100%",
        height:"2px", 
        background:"rgba(255, 255, 255, 0.1)"
    }
}

export default styles;