const internshipBenefits = [
  {
    title: "Real-world project experience",
    benefits: [
      "• Work on cutting-edge ambitious projects",
      "• Apply your knowledge and skills to real-world scenarios."
    ]
  },
  {
    title: "Potential job offers",
    benefits: [
      "• Showcase your talents and prove your worth to potential employers.",
      "• Enhance your odds of obtaining a full-time role with Nada.ba, as we frequently recruit from our intern pool."
    ]
  },
  {
    title: "Chance for compensation",
    benefits: [
      "• Interns may receive pay for work completed, earn commissions, or even obtain equity, depending on their position and performance."
    ]
  },
  {
    title: "Expert mentorship",
    benefits: [
      "• Learn directly from industry veterans with over 10 years of experience."
    ]
  },
  {
    title: "Discovering career paths",
    benefits: [
      "• Explore various roles within the IT sector, such as development, management, marketing, and more.",
      "• Even if you never considered it before, the IT sector is the most rewarding sector you could work in, and there are positions not only for programmers but also for all kinds of backgrounds."
    ]
  },
  {
    title: "Networking opportunities",
    benefits: [
      "• Connect with like-minded peers and professionals & Foster long-lasting relationships in the tech sector that can open doors to future opportunities."
    ]
  },
  {
    title: "Strong portfolio",
    benefits: [
      "• Build an impressive portfolio of completed projects during your internship.",
      "• Stand out from the competition with tangible proof of your capabilities."
    ]
  },
  {
    title: "Exposure to diverse technologies",
    benefits: [
      "• Gain experience with a wide range of tools and programming languages.",
      "• Stay updated on the latest industry trends and innovations.",
      "• Broaden your skillset and increase your marketability as a tech professional."
    ]
  }
];


export default internshipBenefits;