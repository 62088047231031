import React from 'react'
//styles and icons
import styles from './Style_Header'
import './Header.css'
import logo from '../../../../assets/icons/logo.png'
import close from '../../../../assets/icons/close.png'
//link
import { Link } from "react-router-dom";
//helpers
import { useMediaQuery, mobileQuery } from '../../../../helpers/mediaQuery';
import Info from "../../../project-single/components/info/Info"

const Header = () => {
  let isMobile = useMediaQuery(mobileQuery);
  const bigTitleStyle = isMobile ? styles.bigTitleMobile : styles.bigTitle
  // TODO: add blinking to Apply button
  return (
    <div style={isMobile ? styles.mobileWrapper : styles.wrapper}>
      <div style={isMobile ? styles.headerMobile : styles.header}>
        <Link to="/" style={styles.logoLink}>
          <img src={logo} style={{ height: "100%" }} />
        </Link>
      </div>
      <div style={isMobile ? styles.mobileIntro : styles.intro}>
        <span style={bigTitleStyle}>Join the Software Industry, Join </span> <span style={bigTitleStyle}><Link style={styles.link} to="/">Nada.ba</Link></span> 
        <div style={isMobile ? styles.smallTitleMobile : styles.smallTitle}>Join the digital industry with Nada.ba! Explore roles in Development, Sales, Design, Marketing, Content Creation and Managment. Work with experts on real and ambitious projects, and unlock future job opportunities. Take the leap – apply today!</div>
      </div>
      <div>
        <div style={isMobile ? {display: "flex", flexDirection: "column", justifyContent: "space-between"} : {display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          <Info name={"Location"} text={"Fully remote"} />
          <Info name={"Pace"} text={"5 to 20 hours per week at your own pace, you choose your hours"} />
          <Info name={"Criteria"} text={"Every"} />
          <Info 
            name={"How to apply"} 
            buttonText={"APPLY HERE"} 
            onButtonClick={()=>{
              let element = document.getElementById("application-form-apply");
              element.scrollIntoView({
                behavior: 'smooth'
              });
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Header