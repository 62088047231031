import React, { useState } from "react";
import DescriptionText from "../../../../components/description-text/DescriptionText";
import Title from "../../../../components/title/Title";
import blink from "../../../../assets/icons/blink.png";
import styles from "./Style_Welcome";
import Track from "../../components/track/Track";
import { useMediaQuery, mobileQuery } from "../../../../helpers/mediaQuery";
import "./WelcomeAnimations.css";
import LandingPageInternshipCard from "../../../nada-internship/components/landing-page-internship-card/LandingPageInternShipCard";

const Welcome = () => {
  let isMobile = useMediaQuery(mobileQuery);
  const [isInternshipCardVisible, setIsInternshipCardVisible] = useState(true)

  return (
    <div style={isMobile ? null : styles.wrapper}>
      <div style={isMobile ? null : styles.introWithInternship}>
        <div style={isMobile ? styles.intro : null}>
          <Title small>
            <span src={blink} id="blink" />
            WELCOME TO
          </Title>
          <Title>
            NADA.BA
            {/* <img
              id="logo"
              className={isMobile ? "logo-mobile" : "logo-desktop"}
              src={require("../../../../assets/svgs/logo.svg").default}
            /> */}
          </Title>
          <DescriptionText
            customStyles={isMobile ? styles.missionMobile : styles.mission}
          >
            A premium software consulting and development agency <br/><br/>
            <i>Success is the result of focused effort </i> <br/>
          </DescriptionText>
        </div>
        {
          isInternshipCardVisible ? 
            <div style={isMobile ? styles.internshipWrapperMobile : styles.internshipWrapper}>
              <LandingPageInternshipCard handleClick={() => setIsInternshipCardVisible(false)} />
            </div> : 
            null
        }
      </div>
      <div style={isMobile ? styles.partTwoMobile : styles.partTwo}>
        {isMobile && <Track />}
        <Title
          customStyles={isMobile ? styles.productsMobile : styles.products}
        >
          OUR APPROACH
        </Title>
        <DescriptionText
          customStyles={isMobile ? styles.industriesMobile : styles.industries}
        >
         User driven result oriented software outsourcing company. <br/>
          We are a team of professionals with a passion for technology and product. <br/>
          With proven track record of delivering high quality software solutions<br/>
          for both startups and enterprises. We are here to help you build your vision.<br/>
                  </DescriptionText>
      </div>
    </div>
  );
};

export default Welcome;
