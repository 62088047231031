import Title from "../../../../components/title/Title";
import DescriptionText from "../../../../components/description-text/DescriptionText";
import favorites from "./favorites.png";
import map from "./map.png";
import profile from "./profile.png";
import poll from "./poll.png";
import styles from "./Style_Content";
import { useMediaQuery, mobileQuery } from "../../../../helpers/mediaQuery";

const Content = () => {
    const newLine=<br/>
    let isMobile = useMediaQuery(mobileQuery);
    return(
        <div>
            <div style={isMobile? styles.wrapperMobile : styles.wrapper}>
                <div style={styles.line}></div>
                <div style={{...styles.text, ...isMobile? null : styles.row}}>
                    <Title customStyles={isMobile? styles.titleMobile : styles.title}>
                        Problem Statement
                    </Title>
                    <DescriptionText customStyles={isMobile? styles.descriptionMobile : styles.description}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus elit urna, volutpat vitae diam ut, ullamcorper rhoncus magna. Sed consequat iaculis nunc, ut dictum nisi hendrerit sit amet. Vivamus non venenatis sapien, non fringilla ipsum. Fusce consequat eros ac elit tristique rhoncus. Nulla facilisi. Integer vitae consequat libero. Duis aliquet pellentesque mi, sit amet condimentum urna aliquam tincidunt. Vestibulum non turpis congue lacus sodales semper vitae vitae sapien. Quisque a feugiat augue. Phasellus non est felis. Donec tincidunt tempus porttitor. Nam tempor ultrices congue. Mauris efficitur, tortor in euismod aliquet, nisl tortor auctor arcu, ut fermentum ipsum nibh luctus erat. Pellentesque euismod magna sed luctus faucibus.
                        {newLine}{newLine}
                        Suspendisse euismod malesuada neque, sed ultricies risus efficitur sed. Ut mollis posuere neque quis cursus. Curabitur sit amet ipsum a nisi pulvinar iaculis. Phasellus justo nunc, viverra quis viverra ac, congue quis dolor. Quisque placerat, lacus vitae euismod dictum, arcu metus sollicitudin nibh, sit amet egestas erat nisl vitae enim. Sed eleifend eros ornare purus tincidunt, accumsan pellentesque justo posuere. Nulla facilisi. Proin lorem quam, consectetur vitae eros quis, auctor fringilla dui. Vivamus hendrerit hendrerit lacus eu consequat.
                    </DescriptionText>
                </div>
                <div style={{...isMobile? null : styles.row, ...{justifyContent:"space-between"}}}>
                    <img 
                        src={favorites} 
                        style={isMobile? {width:"100%", marginBottom:"2%"} : {width:"49%"}}
                    />
                    <img 
                        src={map} 
                        style={isMobile? {width:"100%"} : {width:"49%"}}
                    />
                </div>
                <div>
                    <img 
                        src={profile} 
                        style={{width:"100%", margin:"2% 0"}}
                    />
                </div>
                <div style={isMobile? null : {marginLeft:"50%"}}>
                    <DescriptionText customStyles={isMobile? styles.descriptionAsideMobile : styles.descriptionAside}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus elit urna, volutpat vitae diam ut, ullamcorper rhoncus magna. Sed consequat iaculis nunc, ut dictum nisi hendrerit sit amet. Vivamus non venenatis sapien, non fringilla ipsum. Fusce consequat eros ac elit tristique rhoncus. Nulla facilisi. Integer vitae consequat libero. Duis aliquet pellentesque mi, sit amet condimentum urna aliquam tincidunt. Vestibulum non turpis congue lacus sodales semper vitae vitae sapien. Quisque a feugiat augue. Phasellus non est felis. Donec tincidunt tempus porttitor. Nam tempor ultrices congue. Mauris efficitur, tortor in euismod aliquet, nisl tortor auctor arcu, ut fermentum ipsum nibh luctus erat. Pellentesque euismod magna sed luctus faucibus.
                    </DescriptionText>
                </div>
            </div>
            <div style={styles.wrapper}>
                <div style={styles.line}></div>
                <div style={{...styles.text, ...isMobile? null : styles.row}}>
                    <Title customStyles={isMobile? styles.titleMobile : styles.title}>
                        UX Results
                    </Title>
                    <DescriptionText customStyles={isMobile? styles.descriptionMobile : styles.description}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus elit urna, volutpat vitae diam ut, ullamcorper rhoncus magna. Sed consequat iaculis nunc, ut dictum nisi hendrerit sit amet. Vivamus non venenatis sapien, non fringilla ipsum. Fusce consequat eros ac elit tristique rhoncus. Nulla facilisi. Integer vitae consequat libero. Duis aliquet pellentesque mi, sit amet condimentum urna aliquam tincidunt. Vestibulum non turpis congue lacus sodales semper vitae vitae sapien. Quisque a feugiat augue. Phasellus non est felis. Donec tincidunt tempus porttitor. Nam tempor ultrices congue. Mauris efficitur, tortor in euismod aliquet, nisl tortor auctor arcu, ut fermentum ipsum nibh luctus erat. Pellentesque euismod magna sed luctus faucibus.
                        {newLine}{newLine}
                        Suspendisse euismod malesuada neque, sed ultricies risus efficitur sed. Ut mollis posuere neque quis cursus. Curabitur sit amet ipsum a nisi pulvinar iaculis. Phasellus justo nunc, viverra quis viverra ac, congue quis dolor. Quisque placerat, lacus vitae euismod dictum, arcu metus sollicitudin nibh, sit amet egestas erat nisl vitae enim. Sed eleifend eros ornare purus tincidunt, accumsan pellentesque justo posuere. Nulla facilisi. Proin lorem quam, consectetur vitae eros quis, auctor fringilla dui. Vivamus hendrerit hendrerit lacus eu consequat.
                    </DescriptionText>
                </div>
                <div>
                    <img 
                        src={poll} 
                        style={{width:"100%"}}
                    />
                </div>
            </div>
            <div style={styles.wrapper}>
                <div style={styles.line}></div>
                <div style={{...styles.text, ...isMobile? null : styles.row}}>
                    <Title customStyles={isMobile? styles.titleMobile : styles.title}>
                        Learnings
                    </Title>
                    <DescriptionText customStyles={isMobile? styles.descriptionMobile : styles.description}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus elit urna, volutpat vitae diam ut, ullamcorper rhoncus magna. Sed consequat iaculis nunc, ut dictum nisi hendrerit sit amet. Vivamus non venenatis sapien, non fringilla ipsum. Fusce consequat eros ac elit tristique rhoncus. Nulla facilisi. Integer vitae consequat libero. Duis aliquet pellentesque mi, sit amet condimentum urna aliquam tincidunt. Vestibulum non turpis congue lacus sodales semper vitae vitae sapien. Quisque a feugiat augue. Phasellus non est felis. Donec tincidunt tempus porttitor. Nam tempor ultrices congue. Mauris efficitur, tortor in euismod aliquet, nisl tortor auctor arcu, ut fermentum ipsum nibh luctus erat. Pellentesque euismod magna sed luctus faucibus.
                    </DescriptionText>
                </div>  
            </div>
               
        </div>
        
    )
}

export default Content;