import zecArchive from "../../assets/images/zecArchive.png";
import feedbackExchange from "../../assets/images/feedbackExchange.png";
import bobTheSheep from "../../assets/images/bobTheSheep.png";
import bookndrive from "../../assets/images/bookndrive.png";
import elimiwait from "../../assets/images/elimiwait.png";
import genijalno from "../../assets/images/genijalno.png";
import huu from "../../assets/images/huu.png";


export const projects = [
  {
    title: 'HUU',
    description: 'Huu is an art project in the shape of a 3d website that portrays the story of survivors of war time sexual violence. It allows the user from inside of their browser to fly from an island to island where they are able to listen to the story of the survivors.',
    typeOfProject: "3d Web Experience",
    date: "2021-Today",
    categories: ["3d Web", "React", "Unreal Engine", "Firebase"],
    thumbnailImage: huu,
    link: "htttps://www.huu.world"
  },
  {
    title: 'GENIJALNO',
    description: 'Genijalno.ba is a software company that hired us a subcontractor to expand their frontend capabilities. With Genijalno we have worked on two projects: BookNDrive and Elimiwait',
    typeOfProject: "Web & Mobile Application",
    date: "2021-Today",
    categories: ["Subcontracting", "React", "React Native"],
    thumbnailImage: genijalno,
    link: "https://www.genijalno.ba"
  },
  {
    title: 'BOOKNDRIVE',
    description: 'Book-n-drive car sharing platform. With more than 1,000 vehicles and over 65,000 active customers, BookNDrive is the largest car sharing provider in Germany. Nada.ba is responsible for the daily maintenance and implementation of new functions according to the users requirements.',
    typeOfProject: "Web & Mobile Application",
    date: "2021-Today",
    categories: ["React", "React Native", "Java", "Testing"],
    thumbnailImage: bookndrive,
    link:"https://web.book-n-drive.de/",
  },
  {
    title: "ELIMIWAIT PARKING",
    description: "Elimiwait is a technology based solution for the valet industry, geared towards streamlining parking procedures and experiences that benefit both customers and staff. Full stack solution for which we were hired on board to develop new features for web and mobile app. ",
    typeOfProject: "Web & Mobile Application",
    date: "2021-2022",
    categories: ["React", "React Native"],
    thumbnailImage: elimiwait,
    link:"https://www.elimiwait.com/"
  },
  {
    title: "ZEC ARCHIVE",
    description:
      "Safet Zec is one of the most famous living painters in the world. Nada.ba had the privilege to create a software system that allowed the client to organize his extensive life's work and keep track of it in a digital archive.",
    typeOfProject: "Web Application",
    date: "2021-Today",
    categories: ["Ui/Ux", "Product Design", "React", "Node.js", "Firebase", "NoSQL"],
    thumbnailImage: zecArchive,
    link:"https://en.wikipedia.org/wiki/Safet_Zec"
  },
  {
    title: "FEEDBACK EXCHANGE",
    description:
      "Original Nada invention that allows all the members of a working community to share and learn from various feedbacks from their colleagues. It is conceived as a kind of social network for professional feedback. Nada used the platform to develop and conduct surveys and it has been personalized according to the Clients needs.",
    typeOfProject: "Web APplication",
    date: "06.06.2022",
    categories: ["Web App", "Mobile App", "Original idea"],
    thumbnailImage: feedbackExchange,
    link:"https://feedback.exchange/"
  },
  // {
  //   title: "Bob The Sheep",
  //   description:
  //     "Bob the SheepO represents the NFT collection. Two creative experts create it to expand their art and brand expertise into the world of NFT’s by creating a more intimate relationship between Bob the Sheepo’s art, fans, and art collectors, and new-age enthusiasts. Neonad helped tloved the idea and helped with website development.",
  //   typeOfProject: "NFT Project",
  //   date: "06.06.2022",
  //   categories: ["Website", "Original idea"],
  //   thumbnailImage: bobTheSheep,
  // },
];
