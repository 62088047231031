const styles = {
    wrapper:{
        marginBottom:"15%"
    },
    wrapperMobile:{
        marginBottom:"10vh"
    },
    description: {
        width:"70%"
    },
    descriptionMobile: {
        width:"100%"
    },
    image:{
        width:"100%",
        marginBottom:"4vh"
    },
    blink: {
        textTransform: "uppercase"
    },
    removeDecorationLink : {
        color: "#fff",
        textDecoration: "none",
    }
}

export default styles;