import huu from "../../../assets/images/huu.png";


const InternshipProject = {
    title: 'Join the Software Industry, Join Nada.ba\'s Summer Internship',
    description: 'Huu is an art project in the shape of a 3d website that potrays the story of survivors of war time sexual violence. It allows the user to user to from inside of their browser to fly from an island to island where they are able to listen the story of the survivors.',
    typeOfProject: "3d Web Experience",
    date: "2021-Today",
    categories: ["3d Web", "React", "Unreal Engine", "Firebase"],
    thumbnailImage: huu,
    link: "www.huu.world"
}

export default InternshipProject;