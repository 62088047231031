import React from 'react'
//components
import Card from '../card/Card'
//styles
import styles from './Style_DropdownMenu'
//helpers
import { useMediaQuery, mobileQuery } from '../../../../helpers/mediaQuery'
//Text
import PositionsDescriptions from '../../text/PositionDescriptions_Text'

const PositionDescriptions = () => {
  let isMobile = useMediaQuery(mobileQuery);

  return (
    <div style={styles.dropdownMenuWrapper}>
      <h2 style={isMobile ? styles.dropdownMenuTitleMobile : styles.dropdownMenuTitle}>Position <br /> descriptions</h2>
      {PositionsDescriptions.map((position, index) => (
        <Card
          key={index}
          cardText={position.title}
          cardDescription={position}
        />
       ))}
    </div>
  )
}

export default PositionDescriptions