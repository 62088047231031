import { spaceWhite } from "../../constants/Colors";

const styles = {
    text:{
        color: spaceWhite,
        fontFamily:"SpaceGrotesk-Medium",
        background:"rgba(255, 255, 255, 0.1)",
        borderRadius: "32px",
        boxSizing:"border-box",
        padding: "1% 2%",
        textTransform:"uppercase",
        marginRight:"0.5%",
        fontSize:"1em"
    },
    textMobile:{
        padding: "2% 4%",
        marginRight:"1%",
        fontSize:"0.8em"
    }
}

export default styles;