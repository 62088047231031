export const styles = {
  mobileStars: {
    position: "fixed",
    width: "450px",
    height: "1200px",
    top: 0,
    zIndex: -1,
  },
  desktopStars: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: -1,
  },
};

export default styles;
