import { white } from "../../constants/Colors";

const styles = {
    text: {
        fontFamily:"SpaceGrotesk",
        fontSize: "6em",
        color: white,
        letterSpacing:"-0.05em"
    },
    small: {
        fontSize:"1em", 
        letterSpacing:"0.025em", 
        fontFamily:"SpaceGrotesk-Medium",
        width:"max-content"
    }
}

export default styles;