const styles = {
    wrapper:{
        paddingTop: "5%",
        zIndex: 2,
    },
    sidePadding:{
        padding: "0 5%"
    },
    wrapperMobile:{
        padding: "5% 0%",
        zIndex: "3 !important",
    },
    projects: {
        width:"90%",
        display:"flex", 
        flexDirection:"row",
        margin:"20vh 0",
        padding: "0 5%",
    },
    thumbnail: {
        marginBottom:"5%",
        width:"50%",
    }
}

export default styles;