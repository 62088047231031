import styles from "./Style_Title";
import { useMediaQuery, mobileQuery } from "../../helpers/mediaQuery";


const Title = ({children, customStyles, small, underline}) => {
    let isMobile = useMediaQuery(mobileQuery);
    return(
        <div style={{
            ...styles.text, 
            ...(isMobile && {fontSize:"3.5em"}),
            ...(small && styles.small), 
            ...(customStyles && customStyles)
            }}
        >
            {children}
            {
                underline && 
                <div className="underline"></div>
            }
        </div>
    )
}

export default Title;