import React, { useState, useEffect } from "react";
import Track from "./components/track/Track";
import Tile from "../../components/tile/Tile";
import Footer from "../../components/footer/Footer";
import Thumbnail from "./components/thumbnail/Thumbnail";
import { projects } from "./AllProjects";
import styles from "./Style_LandingPage";
import { useMediaQuery, mobileQuery } from "../../helpers/mediaQuery";
import StarShower from "./components/star-shower/StarShower";

const Portfolio = () => {
  const [activeProject, setActiveProject] = useState();
  const [scrolled, setScrolled] = useState(false);
  let isMobile = useMediaQuery(mobileQuery);
  let logo;

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      logo = document.getElementById("logo");
    } catch (error) {
    }
  }, []);

  const handleOnScroll = () => {
    if (!scrolled && logo !== undefined && !isMobile) {
      setScrolled(true);
      logo.classList.add("logo-move");
    }
  };

  return (
    <StarShower>
      <div
        style={isMobile ? styles.wrapperMobile : styles.wrapper}
        onWheel={handleOnScroll}
        onTouchMove={handleOnScroll}
      >
        {/* WELCOME IS THE TOP PART OF THE PAGE AND IT CONTAINS THE LOGO AND THE SCROLLING TEXT */}
        {/* <Welcome /> */}
        <div style={styles.projects}>
          <div style={isMobile ? null : { width: "50%" }}>
            {/* MIDDLE PART OF THE PAGE THAT GOES FROM SCROLLING TEXT TO FOOTER
                Projects an array that contains all projects that will be displayed on the Portfolio
                  if you want to create another project or change the preview of the project edit Projects.js  */}
            {projects.map((project) => (
              <Tile
                blog
                link={project.url}
                project={project}
                onHover={() => setActiveProject(project)}
                active={activeProject === project}
                key={project.name}
                title={project.title}
                description={project.description}
                blinkText={project.url ? "READ CASE STUDY" : null}
              />
            ))}
          </div>
          {/* MOBILE VERSION OF PROJECTS */}
          {!isMobile && (
            <div style={styles.thumbnail}>
              {activeProject !== undefined &&
                activeProject.thumbnailImage !== undefined && (
                  <Thumbnail
                    activeProject={activeProject}
                    imageSource={activeProject.thumbnailImage}
                  />
                )}
            </div>
          )}
        </div>
        {/* BOTTOM PART OF THE PAGE */}
        <Footer showContactUs={true}/>
      </div>
    </StarShower>
  );
};

export default Portfolio;
