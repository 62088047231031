import React, { useRef } from 'react';
import baseStar from "../../../../assets/images/BaseStar.svg";
import "./StarAnimations.css";

/**
 ** Represents animated image of a green star.
 *
 * @param {double} x - percentage value for setting the left property (the horizontal position of a component). 
 * @param {double} y - percentage value for setting the top property (the vertical position of a component). 
 * @param {double} delay - value in seconds for setting the animationDelay property 
    (specifying the amount of time to wait from applying the animation to a component 
    before beginning to perform the animation).
 * @param {double} duration - value in seconds for setting the animationDuration property  
    (defines how long an animation should take to complete one cycle).
 */
const BaseStar = ({x, y, delay, duration}) => {
   const ChipStyles = useRef({
       opacity: 0,
       position: 'absolute',
       top: y,
       left: x,
       animationDelay: delay,
       animationDuration: duration,
   });
    
   return <img src={baseStar} className="base-star" style={ChipStyles.current}/>
}
    
export default BaseStar