
import { spaceBackground, white } from "../../../../constants/Colors";

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "max-content",
    padding: "80px"
  },
  wrapperMobile: {
    padding: "30px 20px"
  },
  descriptionWrapper: {
    display: "flex",
    flexDirection: "row",
    // height: "50%",
    width: "100%",
    marginBottom: "48px"
  },
  descriptionWrapperMobile: {
    marginBottom: "48px"

  },
  title: {
    fontSize: "64px",
    fontFamily: "SpaceGrotesk-Medium",
    color: white,
    width: "50%"
  },
  titleMobile: {
    fontFamily: "SpaceGrotesk-Medium",
    fontSize: "32px",
    color: white,
    marginBottom: "15px"
  },
  text: {
    fontFamily: "Inter",
    fontSize: "16px",
    color: white,
    width: "50%"

  },
  textMobile: {
    fontFamily: "Inter",
    fontSize: "16px",
    color: white
  },
  imageWrapper: {
    height: "max-content",
    width: "100%"
  },
  image: {
    width: "100%"
  },
  imageWrapperMobile: {
    height: "max-content",
    width: "100%"
  },
  imageMobile: {
    width: "100%"
  }
};

export default styles;