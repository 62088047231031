import Title from "../title/Title";

const Social = ({linkTo, name}) => {
    return(
        <a 
            target="_blank"
            href={linkTo} 
            style={{marginRight:"5%", textDecoration:"none"}} 
            className="underlinedText">
            <Title small underline>
                {name}
            </Title>
        </a>
    )
}

export default Social;