import React from 'react'
import { useState, useEffect } from 'react'
//styles and icons
import styles from './Style_Card'
import './Card.css'
import blink from '../../../../assets/icons/blink.png'
import blackBlink from '../../../../assets/icons/blackBlink.png'
import arrow from '../../../../assets/icons/arrow.png'
import blackArrow from '../../../../assets/icons/blackArrow.png'
//helpers
import { useMediaQuery, mobileQuery } from '../../../../helpers/mediaQuery'

const Card = ({ isPosition = true, cardText, cardDescription }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [blinking,setBlinking]=useState(false)
  let isMobile = useMediaQuery(mobileQuery);


  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setAnimationComplete(true);
    }, 300);

    return () => {
      setAnimationComplete(false)
      clearTimeout(animationTimeout)
    };
  }, [isClicked]);

  const handleClick = () => {
    setIsClicked(prevState => !prevState);
    setBlinking(true)
  };

  return (
    // TODO: fix mobile card padding, it's different on animation.
    <div
      onClick={handleClick}
      style={isMobile ? styles.closedCardWrapperMobile : styles.closedCardWrapper}>
      {!isClicked ?
        (<div style={isMobile ? styles.cardWrapperMobile : styles.cardWrapper}>
          <div style={isMobile ? styles.cardTextWrapperMobile : styles.cardTextWrapper}>
            <img src={blink} alt='blink' id={blinking ? 'blink' : ''} style={styles.blink} />
            <span style={styles.cardText}>{cardText}</span>
          </div>
          <button style={isMobile ? styles.cardArrowWrapperMobile : styles.cardArrowWrapper}>
            <img src={arrow} alt='arrow' style={styles.arrow} />
          </button>
        </div>)
        :
        (
          <div
            style={isMobile ? styles.openedCardWrapperMobile : styles.openedCardWrapper}>
            {animationComplete &&
             <div style={styles.contentWrapper}>
                <div style={isMobile ? styles.cardWrapperMobile : styles.cardWrapper}>
                  <div style={isMobile ? styles.clickedCardWrapperMobile : styles.clickedCardWrapper}>
                    <div style={styles.clickedTitleWrapper}>
                      <img src={blackBlink} alt='blink' id={blinking ? 'blink' : ''} style={styles.blink} />
                      <span style={styles.clickedCardTitle}>{cardText}</span>
                    </div>
                  </div>
                  <button style={isMobile ? styles.cardArrowWrapperMobile : styles.cardArrowWrapper}>
                    <img src={blackArrow} alt='blackArrow' style={styles.arrow} />
                  </button>
                </div>
                {isPosition ? (
                  // POSITION DESCRIPTION TEXT 
                  <span style={isMobile ? styles.cardDescriptionMobile : styles.cardDescription}>
                    {cardDescription.description && (
                      <>
                        {cardDescription.description}
                        <br/><br/>
                      </>
                    )}
                    {cardDescription.technologies && (
                      <>
                        {cardDescription.technologies}
                        <br/><br/>
                      </>
                    )}
                    {cardDescription.compensation && (
                      <>
                        {cardDescription.compensation}
                        <br/><br/>
                      </>
                    )}
                    {cardDescription.hours && cardDescription.hours}
                  </span>) 
                  // BENEFITS  
                  : (
                    <span style={isMobile ? styles.cardDescriptionMobile : styles.cardDescription}>
                      {
                        cardDescription.map((benefit) =>{
                          return (
                            <>
                              {benefit}
                              <br/>
                            </>
                          )
                        })
                      }</span>
                  )
                }
              </div>
            }
          </div>)
      }
    </div>
  )
}
export default Card