import styles from "./Style_DescriptionText"

const DescriptionText = ({children, customStyles}) => {
    return(
        <div style={{
            ...styles.text, 
            ...(customStyles && customStyles)
            }}
        >
            {children}
        </div>
    )
}

export default DescriptionText;