import { spaceSurface, white, spaceGreen, spaceWhite } from '../../../../constants/Colors';

const styles = {
  wrapper: {
    paddingLeft: "80px",
    paddingRight: "80px"
  },
  mobileWrapper: {
    paddingLeft: "24px",
    paddingRight: "24px"
  },
  header:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    margin:"20px 0 5vh",
    // position:"fixed",
    width:"88%",
    height:"80px",
    alignItems:"center",
    zIndex: 100
  },
  headerMobile:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    margin:"20px 0 5vh",
    // position:"fixed",
    width:"88%",
    height:"50px",
    alignItems:"center",
    zIndex: 100
  },
  logo: {
    width: '5.23%'
  },
  intro: {
    marginTop: "80px",
    height: "max-content",
    marginBottom: "70px"
  },
  mobileIntro: {
    marginTop: "50px",
    height: "max-content",
    marginBottom: "48px"
  },
  bigTitle:{
    fontSize: "76px",
    color: white,
    fontFamily: 'SpaceGrotesk-Medium',
  },
  bigTitleMobile:{
    fontSize: "46px",
    color: white,
    fontFamily: 'SpaceGrotesk-Medium',
  },
  link: {
    color: spaceGreen
  },
  logoLink: {
    marginTop: "10px", 
    height: "100%"
  },
  smallTitle: {
    fontFamily: "Inter",
    color: spaceWhite,
    fontSize: "14px",
    lineHeight: "21px",
    width: "30%",
    marginTop: "20px"
  },
  smallTitleMobile: {
    fontFamily: "Inter",
    color: spaceWhite,
    fontSize: "14px",
    lineHeight: "21px",
    width: "100%",
    marginTop: "8px"
  },

};

export default styles;