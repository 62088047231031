import React, { useRef, useEffect, useState } from 'react'
//styles and icons
import styles from './LandingPageInternshipCard_Style'
import close from '../../../../assets/icons/closeInternshipReminder.png'
import "./LandingPageInternshipCard.css";
//link
import { Link } from "react-router-dom";

//helpers
import { useMediaQuery, mobileQuery } from '../../../../helpers/mediaQuery';

const LandingPageInternshipCard = ({handleClick}) => {
  let isMobile = useMediaQuery(mobileQuery)
  const componentRef = useRef(null);
  const  [isFixed, setIsFixed] = useState(false)

  useEffect(() => {
    if(!isMobile) {
      const handleScroll = () => {
      const offset = 50; // Adjust this value as needed
      const componentTop = componentRef.current.getBoundingClientRect().top;
      if(window.scrollY < window.innerHeight/2) {
        setIsFixed(false)
      } else {
        setIsFixed(componentTop <= offset);
      }
    };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
   console.log(isFixed)
      
  }, [isFixed]);

  return (
    <div 
      ref={componentRef} 
      style={isMobile ? styles.wrapperMobile : {...styles.wrapper, ...isFixed && styles.wrapperFixed}}
    >
      <div style={{display: "flex", justifyContent:"end", marginBottom: "15px"}}>
        <img onClick={handleClick} src={close} style={{width: "14px", height:"14px"}} />
      </div>
      <div style={isMobile ? styles.titleMobile : styles.title}>
        COOLEST ADVENTURE THIS SUMMER
      </div>
      <div style={isMobile ? styles.textMobile : styles.text}>
        iNTERNSHiP at NADA.ba 2024
      </div>
      <Link to="internship" style={isMobile ? styles.linkMobile : styles.link}>
        CHECK OUT NOW
        <span id="blinkLanding"/>
      </Link>
    </div>
  )
}

export default LandingPageInternshipCard