import {spaceBackground} from '../../../../constants/Colors'

const styles = {
  dropdownMenuWrapper: {
    width:'89%',
    display:'flex',
    flexDirection:'column',
    height:'auto',
    marginTop:"70px"
  },
  dropdownMenuTitle:{
    color:'white',
    fontFamily:'SpaceGrotesk',
    fontSize:'4em',
    margin:'0'
  },
  dropdownMenuTitleMobile:{
    color:'white',
    fontFamily:'SpaceGrotesk',
    fontSize:'32px',
    margin:'0',
    padding: '20px 0'
  }
};

export default styles;