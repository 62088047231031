const styles = {
  wrapper: {
    marginTop: "50vh",
    padding: "0 5%",
  },
  intro: {
    height: "90vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: "0 6.64%",
    width: "40%"
  },
  mission: {
    // width: "35%",
  },
  missionMobile: {
    width: "100%",
    marginBottom: "15vh",
  },
  partTwo: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "15% 0",
  },
  partTwoMobile: {
    display: "flex",
    flexDirection: "column",
    padding: "25% 0",
  },
  products: {
    fontSize: "4em",
    width: "50%",
  },
  productsMobile: {
    fontSize: "2em",
    marginTop: "5vh",
    padding: "0 6.64%",
  },
  industries: {
    width: "50%",
    margin: "2vh 5%",
    fontSize: "1.5rem",
  },
  industriesMobile: {
    marginTop: "2.5vh",
    padding: "0 6.64%",
  },
  introWithInternship: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  internshipWrapper: {
    width: "40%"
  },
  internshipWrapperMobile: {
    width: "100%"
  }
};

export default styles;
