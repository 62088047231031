import React, { useState, useEffect } from "react";
import Tile from "../tile/Tile";
import Social from "../social/Social";
import DescriptionText from "../description-text/DescriptionText";
import Title from "../title/Title";
import logoSvg from "../../assets/svgs/logo.svg";
import logoPng from "../../assets/icons/logo.png";
import styles from "./Style_Footer";
import { spaceWhite } from "../../constants/Colors";
import { useMediaQuery, mobileQuery } from "../../helpers/mediaQuery";

const Footer = ({showContactUs}) => {
  let isMobile = useMediaQuery(mobileQuery);
  let UTCHours = new Date().toLocaleTimeString('en-US', { hour12: false }).substring(0, 2);
  
  const [hours, setHours] = useState(UTCHours);
  const [minutes, setMinutes] = useState(
    new Date().toLocaleTimeString('en-US', { hour12: false }).substring(3, 5)
  );
  useEffect(() => {
    setInterval(() => {
      const updatedHours = new Date().toLocaleTimeString('en-US', { hour12: false }).substring(0, 2);
      const updatedMinutes = new Date().toLocaleTimeString('en-US', { hour12: false }).substring(3, 5);
      setHours(updatedHours);
      setMinutes(updatedMinutes);
    }, 15000);
  }, []);
  return (
    // TODO: fix time.
    <div style={styles.wrapper}>
       <div>
        {
          showContactUs ? 
          <div style={isMobile ? styles.tileMobile : styles.tile}>
            <Tile
              type={"email"}
              blinkText={"contact@nada.ba"}
              title={"CONTACT US!"}
              description={
                "If you have a great idea, we think we can help. If you think our ideas are cool. Be our partner. Say hi"
              }
            />
          </div> : null
        }
        <div style={isMobile ? styles.contactMobile : styles.contact}>
          <div style={isMobile ? styles.socialMobile : styles.social}>
            <DescriptionText>Follow us on social media</DescriptionText>
            <div style={styles.linksSocial}>
              <Social
                linkTo={"https://instagram.com/nada.ba__"}
                name={"INSTAGRAM"}
              />
              {/*<Social
                    name={"LINKEDIN"}
                /> */}
            </div>
          </div>
          <div
            style={{
              ...styles.info,
              ...(isMobile && { marginBottom: "10%" }),
            }}
          >
            <Title customStyles={isMobile ? styles.timeMobile : styles.time}>
              <span className="footer-time">{hours}</span>
              <span id="footer-animation">:</span>
              <span className="footer-time">{minutes} </span>
            </Title>
            <DescriptionText
              customStyles={{
                ...styles.address,
                ...(isMobile && { width: "100%" }),
              }}
            >
              We are a bunch of young people working on things that inspire us,
              help us grow and show us new horizons.
            </DescriptionText>
            <div className="underlinedText">
              <Title small underline>
                <a
                  style={styles.removeDecorationLink}
                  href="mailto:contact@nada.ba"
                >
                  contact@nada.ba
                </a>
              </Title>
            </div>
          </div>
        </div>
      </div>
      <div style={isMobile ? styles.lineMobile : styles.line}></div>
      <div
        style={{ ...styles.endSection, ...(isMobile && { margin: "4% 0%" }) }}
      >
        <img
          src={logoSvg}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = { logoPng };
          }}
        />
        <Title small customStyles={{ color: spaceWhite }}>
        2019 - 2023 and onwards © NADA.BA
        </Title>
      </div>
    </div>
  );
};

export default Footer;
