import React from 'react'
//components
import Card from '../card/Card'
//styles
import styles from './Style_DropdownMenu'
//helpers
import { useMediaQuery, mobileQuery } from '../../../../helpers/mediaQuery'
//text
import internshipBenefits from '../../text/Benefits_Text'

const Benefits = () => {
  let isMobile = useMediaQuery(mobileQuery);

  return (
    <div style={styles.dropdownMenuWrapper}>
      <h2 style={isMobile ? styles.dropdownMenuTitleMobile : styles.dropdownMenuTitle}>Benefits</h2>
      {internshipBenefits.map((benefit, index) => (
        <Card
          key={index}
          isPosition={false}
          cardText={benefit.title}
          cardDescription={benefit.benefits}
        />
       ))}
    </div>
  )
}

export default Benefits