import "./Styles.css";
import { useMediaQuery, mobileQuery } from "../../../../helpers/mediaQuery";

/**
 ** This component represents texts that moves on it’s own (free from user input).
 ** The top text moves to the left and the bottom text moves to the right infinitely.
 ** Method useMediaQuery is called for applying different styles for mobile and desktop version.
 */
const Track = () => {
    let isMobile = useMediaQuery(mobileQuery);
    return(
        <div >
            <div className='wrapper'>
                <p className={isMobile ? "font-size-mobile p-wrapper" : "p-wrapper"}>
                    <span className="left-1">&nbsp;WEB APPS • MOBILE APPS • CONSULTING • UI / UX • BUSINESS ANALYSIS • AI •</span>
                    <span className="left-2">&nbsp;WEB APPS • MOBILE APPS • CONSULTING • UI / UX • BUSINESS ANALYSIS • AI •</span>
                </p>
                <p className={isMobile ? "font-size-mobile p-wrapper" : "p-wrapper"}>
                    <span className="right-1">&nbsp;WEB APPS • MOBILE APPS • CONSULTING • UI / UX • BUSINESS ANALYSIS • AI •</span>
                    <span className="right-2">&nbsp;WEB APPS • MOBILE APPS • CONSULTING • UI / UX • BUSINESS ANALYSIS • AI •</span>  
                </p>
            </div>
        </div>
    )
}

export default Track;
