const firebaseConfig = {
  apiKey: "AIzaSyCWZDWQGBr46FrbIMrbIxES4yiTHZgw6kE",
  authDomain: "nada-c9a91.firebaseapp.com",
  databaseURL: "https://nada-c9a91-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "nada-c9a91",
  storageBucket: "nada-c9a91.appspot.com",
  messagingSenderId: "449074947304",
  appId: "1:449074947304:web:2eac73ef8453766d8fd55d",
  measurementId: "G-B42STETMD1"
};

export default firebaseConfig;