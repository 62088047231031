import Chip from "../../../../components/chip/Chip";
import styles from "./Style_Thumbnail";

const Thumbnail = ({imageSource, activeProject}) => {
    return(
        <div style={styles.wrapper}>
            <div>
              {activeProject.categories && activeProject.categories.map((category) => (
                    <Chip
                        key={activeProject.categories.indexOf(category)}
                        chipText={category}
                    />
                ))}  
            </div>
            
            <img src={imageSource} style={styles.image}/>
        </div>
        
    )
}

export default Thumbnail;