import { spaceGreen, white } from '../../../../constants/Colors';

const styles = {
  wrapper: {
    backgroundColor: spaceGreen,
    width: "max-content",
    padding: "30px 20px 52px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection:"column",
    boxSizing: "border-box",
    zIndex: "200",
    width: "35vw"
  },
  wrapperFixed: {
    position: "fixed",
    top: "50px",
    width: "35vw",
  },
  wrapperMobile: {
    backgroundColor: spaceGreen,
    // width: "max-content",
    margin: "30px 5%",
    position: "fixed",
    top: "0",
    padding: "30px 20px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection:"column",
    boxSizing: "border-box",
    zIndex: "150",
    width: "90%"
  },
  title: {
    fontSize: "16px",
    marginBottom: "16px",
    fontFamily: "SpaceGrotesk-Medium"
  },
  titleMobile: {
    fontSize: "16px",
    marginBottom: "16px",
    fontFamily: "SpaceGrotesk-Medium"
  },
  text: {
    fontSize: "32px",
    marginBottom: "20px",
    fontFamily: "SpaceGrotesk-Medium"
  },
  textMobile: {
    fontSize: "32px",
    marginBottom: "20px",
    fontFamily: "SpaceGrotesk-Medium"
  },
  link: {
    backgroundColor: "black",
    color: white,
    padding: "10px 20px",
    fontSize: "16px",
    fontFamily: "SpaceGrotesk-Medium",
    textDecoration:"none",
    width:"max-content",
    display: "flex",
    alignItems:"center",
    boxShadow: "4px 4px 0px 0px rgba(255, 255, 255, 1)"
  },
  linkMobile: {
    backgroundColor: "black",
    color: white,
    padding: "10px 20px",
    fontSize: "16px",
    fontFamily: "SpaceGrotesk-Medium",
    textDecoration:"none",
    width:"max-content",
    display: "flex",
    alignItems:"center",
    boxShadow: "4px 4px 0px 0px rgba(255, 255, 255, 1)"

  }
};

export default styles;