
// depedencies
import React, { useState, useRef, useEffect } from 'react';
// styles
import styles from './Style_ApplicationForm';
import upload from '../../../../assets/icons/upload.png'
import exit from '../../../../assets/icons/exitButton.png'
import blink from '../../../../assets/icons/blink.png'
import './ApplicationForm.css'
//helpers
import { useMediaQuery, mobileQuery } from '../../../../helpers/mediaQuery';
//dev
import {base64Docsx} from '../../../../constants/dummy-data/base64Docx';
import {base64Pdf} from '../../../../constants/dummy-data/base64Docx';
//text
import PositionsDescriptions from '../../text/PositionDescriptions_Text';

const devApiUrl = "http://127.0.0.1:5001/nada-c9a91/europe-west1/InternshipApplication";
const prodApiUrl = "https://europe-west1-nada-c9a91.cloudfunctions.net/InternshipApplication";
const apiUrl = prodApiUrl;





const ApplicationForm = () => {
  
  let isMobile = useMediaQuery(mobileQuery);
  // Candidate User Input Data 
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [position, setPosition] = useState('');
  const [cv, setCV] = useState(null);
  const [cvBlob, setCVBlob] = useState('');

  const cvInputRef = useRef(null);

  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState("SUBMIT APPLICATION")
  const [isFileSizeError, setIsFileSizeError] = useState(false);


  const handleSubmit = async (event) => {
    event.preventDefault();

    const candidate = JSON.stringify({
      name: name,
      email: email,
      position: position,
      cv: cvBlob
    })
  
    // send the POST request
    try {
      setSubmitStatus("SUBMITTING");
      const response = await fetch(apiUrl, {
        method: 'POST',
        body: candidate
      }).then((response)=>{
        if(response.status === 200) {
          setSubmitStatus("SUCCESS!");
        } else {
          setSubmitStatus("ERROR, TRY MAILING join@nada.ba");
        }
      }).catch((error)=>{
        setSubmitStatus("ERROR, TRY MAILING join@nada.ba");
      });
      
    } catch (error) {
      console.error('Error:', error);
      setSubmitStatus("ERROR, TRY MAILING join@nada.ba")
    }
  };
  
  //dev purposes only
  useEffect(()=>{
    // handleSubmit();
  },[])

  const handleButtonClick = () => {
    cvInputRef.current.click();
  };
  
  const handleCVChange = (e) => {
    const selectedCV = e.target.files[0];
    if(selectedCV.size > 1048576) {
      setIsFileSizeError(true)
      setCV(selectedCV);
    } else {
      if(isFileSizeError) {
        setIsFileSizeError(false)
      }
      setCV(selectedCV);
    }
    const reader = new FileReader();
    reader.readAsDataURL(selectedCV);
    reader.onloadend = () => {
      setCVBlob(reader.result);
    };
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePositionChange = (e) => {
    setPosition(e.target.value);
  };

  const handleDeleteCV = (e) => {
    e.preventDefault();
    setIsFileSizeError(false)
    cvInputRef.current.value = ''
    setCV(null);
  };

  const isUploadDisabled = !(submitStatus==="SUBMIT APPLICATION") ? true : false
  
  return (
    <div id="application-form-apply" style={styles.formWrapper}>
      <form id="apply-form" style={isMobile ? styles.formContainerMobile : styles.formContainer}>
        <h2 style={isMobile ? styles.formTitleMobile : styles.formTitle}>Apply</h2>
        <p style={
            isMobile ? 
              styles.formSubTitleMobile : 
              styles.formSubTitle
            }>
            You can also apply by sending an email to join@nada.ba
        </p>
        <input
          type="text"
          id="name"
          placeholder='Name'
          value={name}
          onChange={handleNameChange}
          style={isMobile ? styles.inputTextFieldMobile : styles.inputTextField}
        />
        <input
          type="email"
          id="email"
          placeholder='Email'
          value={email}
          onChange={handleEmailChange}
          style={isMobile ? styles.inputTextFieldMobile : styles.inputTextField}
        />
        <select id="position" value={position} onChange={handlePositionChange} style={isMobile ? styles.selectFieldMobile : styles.selectField}>
          <option value="">Preferred Position</option>
          {
            PositionsDescriptions.map(position=>{
              return <option value={position.title}>{position.title}</option>
            })
          }
        </select>
        <button
          type="button"
          onClick={handleButtonClick}
          style={isMobile ? styles.uploadFieldMobile : styles.uploadField}
        >
          <p style={styles.buttonText}>Upload CV</p>
          <img src={upload} alt="Upload" style={styles.uploadImage} />
        </button>
        <input
          type="file"
          id="cv"
          ref={cvInputRef}
          accept=".pdf,.doc,.docx"
          onChange={handleCVChange}
          style={{ display: 'none' }}
        />
        <div style={isMobile ? {...styles.cvInfoWrapperMobile, ...isFileSizeError && styles.cvInfoWrapperOnError} : {...styles.cvInfoWrapper, ...isFileSizeError && styles.cvInfoWrapperOnError}}>
          <div style={styles.cvInfoContainer}>
            <img src={blink} alt='exit' style={styles.blink} />
            {
              isFileSizeError ? 
              <p style={styles.infoText}>{cv ? `${cv.name} : Invalid file size,maximum upload size is 1 MB. ` : ''}</p> :
              <p style={styles.infoText}>{cv ? `${cv.name} - ${cv.size} bytes` : ''}</p>  
            }
          </div>
          <button onClick={(e)=> handleDeleteCV(e)} style={styles.exitButton}>
            <img src={exit} alt='exit' />
          </button>
        </div>
        <button 
          disabled={isUploadDisabled}
          onClick={handleSubmit} 
          type={"submit"} 
          style={{
            ...isMobile ? styles.submitButtonMobile : styles.submitButton,
            ...isUploadDisabled && {backgroundColor: "gray"},
            ...(submitStatus === "SUCCESS!") && {color: "#00DEA6"}
          }}
        >
          {submitStatus}
        </button>
      </form>
    </div>
  );
};

export default ApplicationForm;
