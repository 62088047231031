import { spaceBackground } from "../../constants/Colors";

const styles = {
  NadaInternshipWrapper: {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',
    zIndex:'12'
  },
};

export default styles;