import { spaceWhite, spaceGreen  } from "../../../../constants/Colors";

const styles = {
    name:{
        color: "#FFFFFF80",
        textTransform:"uppercase",
        marginBottom:"5px",
        fontSize: "16px"
    },
    nameMobile:{
        color: "#FFFFFF80",
        textTransform:"uppercase",
        marginBottom:"1%",
        fontSize: "16px"
    },
    text:{
        color:spaceWhite,
        fontFamily: "Inter",
        fontSize: "14px"
    },
    textMobile:{
        color:spaceWhite,
        marginBottom:"5%",
        fontFamily: "Inter",
        fontSize: "14px"
    },
    buttonWrapper: {
      backgroundColor: spaceGreen,
      width: "max-content",
      fontFamily: "SpaceGrotesk-Medium",
      fontSize:"16px",
      border: "none",
      borderRadius: "2px",
      color: "black",
      display: "flex",
      alignItems: "center",
      padding: "5px 10px 5px 10px"
    }
}

export default styles;