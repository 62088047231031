// Dependencies
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import StarfieldAnimation from "react-starfield-animation";
// Pages
import LandingPage from "./pages/landing/LandingPage";
import Portfolio from "./pages/landing/Portfolio";
import ProjectPage from "./pages/project-single/project-page/ProjectPage";
import NadaInternship from "./pages/nada-internship/NadaInternship";
import MapToBePrivacyPolicy from "./pages/mapToBe-privacy-policy/MapToBePrivacyPolicy";
// Styling
import "./App.css";
import { useMediaQuery, mobileQuery } from "../src/helpers/mediaQuery";
import styles from "./styles/Style_StarfieldAnimations";
// Analytics
import {initializeApp}  from 'firebase/app';
import {getAnalytics, logEvent } from 'firebase/analytics';
import firebaseConfig from "./constants/FirebaseConfig";

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const analyticsEventLog = (nameOfEvent) => {
  if(app && analytics) {
    logEvent(analytics, nameOfEvent);
  }
}

function App() {

  

  let isMobile = useMediaQuery(mobileQuery);

  const LandingWrapper = () => {
    return (
      <>
        {/* Mobile and Desktop StarfieldAnimation have different styling and desktop has more stars */}
        {isMobile ? (
          <StarfieldAnimation style={styles.mobileStars} />
        ) : (
          <StarfieldAnimation numParticles={500} style={styles.desktopStars} />
        )}
        <LandingPage />
      </>
    );
  };

  return (
    <>   
    {isMobile ? (
      <StarfieldAnimation id={"starfieldAnimation"} style={styles.mobileStars} />
    ) : (
      <StarfieldAnimation id={"starfieldAnimation"} numParticles={500} style={styles.desktopStars} />
    )}
    <Router>
      <Switch>
        <Route path="/u-gain" component={ProjectPage} />
        <Route path="/internship" component={NadaInternship}/>
        <Route path="/portfolio" component={Portfolio} />
        <Route path="/MapToBe-Privacy-Policy" component={MapToBePrivacyPolicy} />
        <Route path="/" component={LandingPage} />
        {/* <Route path="/" render={()=><LandingWrapper />} /> */}
      </Switch>
    </Router>
    </>

  );
}

export default App;
