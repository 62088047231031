import styles from "./Style_Chip";
import { useMediaQuery, mobileQuery } from "../../helpers/mediaQuery";

const Chip = ({chipText}) => {
    let isMobile = useMediaQuery(mobileQuery);
    return(
        <span style={{...styles.text, ...isMobile && styles.textMobile}}>
            {chipText}
        </span>
    )
}

export default Chip;