import { spaceBackground, white, spaceGreen, spaceSurface } from "../../../../constants/Colors";

const styles = {
  formWrapper: {
    width: '89%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex:'20',
    marginBottom: '30px',
    marginTop: "70px"
  },
  formContainer: {
    width: '49.3%',
    display: 'flex',
    flexDirection: 'column'
  },
  formContainerMobile: {
    width: '100%',
    display: 'flex',
    minHeight: '70vh',
    flexDirection: 'column'
  },
  formTitle: {
    marginBottom:'5%',
    fontFamily: 'SpaceGrotesk',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '4em',
    lineHeight: '100%',
    letterSpacing: '-0.05em',
    fontFeatureSettings: "'ornm' on, 'ss01' on",
    color: 'rgba(255, 255, 255, 0.9)'
  },
  formTitleMobile: {
    marginBottom:'5%',
    fontFamily: 'SpaceGrotesk',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '64px',
    lineHeight: '100%',
    letterSpacing: '-0.05em',
    fontFeatureSettings: "'ornm' on, 'ss01' on",
    color: 'rgba(255, 255, 255, 0.9)'
  },
  formSubTitle: {
    marginBottom:'5%',
    fontFamily: 'SpaceGrotesk',
    fontStyle: 'normal',
    fontSize: '1.2em',
    lineHeight: '100%',
    letterSpacing: '-0.05em',
    fontFeatureSettings: "'ornm' on, 'ss01' on",
    color: 'rgba(255, 255, 255, 0.9)'
  },
  formSubTitleMobile: {
    marginBottom:'5%',
    fontFamily: 'SpaceGrotesk',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '100%',
    letterSpacing: '-0.05em',
    fontFeatureSettings: "'ornm' on, 'ss01' on",
    color: 'rgba(255, 255, 255, 0.9)'
  },
  inputTextField: {
    backgroundColor: spaceSurface,
    borderRadius: ' 6px',
    padding: '1.6%',
    marginBottom: '4%',
    color: white
  },
  inputTextFieldMobile: {
    backgroundColor: spaceSurface,
    borderRadius: ' 6px',
    padding: '0 1.6%',
    marginBottom: '40px',
    color: white,
    height: '42px',
    fontSize: '14px'
  },
  selectField: {
    backgroundColor: spaceSurface,
    borderRadius: ' 6px',
    width: '100%',
    padding: '1.6%',
    color: 'rgba(255, 255, 255, 0.6)',
    marginBottom: '4%'
  },
  selectFieldMobile: {
    backgroundColor: spaceSurface,
    borderRadius: ' 6px',
    width: '100%',
    padding: '0 1.6%',
    color: 'rgba(255, 255, 255, 0.6)',
    marginBottom: '40px',
    height: '42px'
  },
  uploadField: {
    padding: '8.5px 17.5px',
    width: '158px',
    height: '38px',
    border: '1px solid #00DEA6',
    borderRadius: '6px',
    marginBottom: '4%',
    position: 'relative',
    backgroundColor: 'transparent',
    fontFamily: 'SpaceGrotesk',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '1em',
    lineHeight: '100%',
    letterSpacing: '0.025em',
    fontFeatureSettings: "'ss01' on, 'ss05' on, 'ss02' on",
    color: spaceGreen,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex:'24'
  },
  uploadFieldMobile: {
    padding: '8.5px 17.5px',
    width: '158px',
    height: '38px',
    border: '1px solid #00DEA6',
    borderRadius: '6px',
    marginBottom: '40px',
    position: 'relative',
    backgroundColor: 'transparent',
    fontFamily: 'SpaceGrotesk',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '1em',
    lineHeight: '100%',
    letterSpacing: '0.025em',
    fontFeatureSettings: "'ss01' on, 'ss05' on, 'ss02' on",
    color: spaceGreen,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex:'24'
  },
  uploadImage: {
    position: 'absolute',
    left: '4%',
    top: '14%',
    zIndex: '16',
  },
  buttonText: {
    marginLeft: '18%'
  },
  submitButton: {
    width: '100%',
    backgroundColor: spaceGreen,
    color: spaceBackground,
    borderRadius: '2px',
    border: 'none',
    padding: '16px 20px',
    fontFamily: 'SpaceGrotesk-Medium',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '1em',
    lineHeight: '100%',
    letterSpacing: '0.025em',
    fontFeatureSettings: "'ss01' on, 'ss05' on, 'ss02' on"
  },
  submitButtonMobile: {
    width: '100%',
    backgroundColor: spaceGreen,
    color: spaceBackground,
    borderRadius: '2px',
    padding: '16px 20px',
    fontFamily: 'SpaceGrotesk-Medium',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    border: 'none',
    lineHeight: '100%',
    letterSpacing: '0.025em',
    fontFeatureSettings: "'ss01' on, 'ss05' on, 'ss02' on"
  },
  cvInfoWrapper: {
    width: '96%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: spaceBackground,
    border: '1px solid #A7DED0',
    borderRadius: '0px',
    color: 'rgba(255, 255, 255, 0.87)',
    fontSize: '1em',
    marginBottom: '4%',
    padding: '0% 2%'
  },
  cvInfoWrapperOnError: {
    border: '1px solid rgba(248, 113, 113, 1)'
  },
  cvInfoWrapperMobile: {
    width: '96%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: spaceBackground,
    border: '1px solid #A7DED0',
    borderRadius: '0px',
    color: 'rgba(255, 255, 255, 0.87)',
    fontSize: '1em',
    marginBottom: '40px',
    padding: '0% 2%'
  },
  cvInfoContainer: {
    width:'90%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  exitButton: {
    backgroundColor: spaceBackground,
    border: 'none'
  },
  infoText: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '1em',
    lineHeight: '150%',
    letterSpacing: '-0.025em',
    color: 'rgba(255, 255, 255, 0.87)',
    marginLeft:'2%'
  },
  blink:{
    width:'6px'
  }
};

export default styles;