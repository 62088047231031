import Title from "../title/Title";
import DescriptionText from "../description-text/DescriptionText";
import Chip from "../chip/Chip";
import blink from "../../assets/icons/blink.png";
import styles from "./Style_Tile";
import { useMediaQuery, mobileQuery } from "../../helpers/mediaQuery";

const Tile = ({
  blog,
  blinkText,
  type,
  link,
  active,
  onHover,
  title,
  description,
  project,
}) => {

  let isMobile = useMediaQuery(mobileQuery);
  return (
    <a 
      href={project && project.link}
      target="_blank" 
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      <div
        style={{
          ...styles.wrapper,
          ...(isMobile && styles.wrapperMobile),
          ...(blog && !active && !isMobile && { opacity: "0.5" }),
        }}
        onMouseEnter={onHover}
        className={blog ? "tile" : null}
      >
        {isMobile && blog && (
          <img src={project.thumbnailImage} style={styles.image} />
        )}
        {blog && (
          <div style={isMobile ? { marginBottom: "2%" } : null}>
            <Chip chipText={project.typeOfProject} />
            <Chip chipText={project.date} />
          </div>
        )}

        <Title
          customStyles={
            blog
              ? null
              : { ...{ fontSize: "4em" }, ...(isMobile && { fontSize: "2em" }) }
          }
        >
          {title}
        </Title>
        <DescriptionText
          customStyles={isMobile ? styles.descriptionMobile : styles.description}
        >
          {description}
        </DescriptionText>
        {blinkText && (
          <div className="underlinedText">
            {blog ? (
              // <Link to={link} style={{ textDecoration: "none" }}>
                <Title small underline customStyles={styles.blink}>
                  <img src={blink} id="blink" />
                  {blinkText}
                </Title>
              // </Link> 
            ) : (
              <Title small underline customStyles={styles.blink}>
                <img src={blink} id="blink" />
                {(type = "mail") ? (
                  <a
                    style={styles.removeDecorationLink}
                    href={`mailto: contact@nada.ba`}
                  >
                    contact@nada.ba
                  </a>
                ) : (
                  { blinkText }
                )}
              </Title>
            )}
          </div>
        )}
      </div>
    </a>
  );
};

export default Tile;
