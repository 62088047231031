const styles = {
    wrapper:{
        display:"flex",
        flexDirection:"column",
        marginLeft:"10vmin",
        boxSizing:"border-box",
        position:"-webkit-sticky",
        position:"sticky",
        top:"5vh",
    },
    image: {
        marginTop:"5%",
    }
}

export default styles;