import React, { useEffect } from 'react'
//styles and icons
import styles from './NadaInternship_Style'
//components
import PositionDescriptions from './components/dropdown-menu/PositionDescriptions'
import Benefits from './components/dropdown-menu/Benefits'
import ApplicationForm from './components/application-form/ApplicationForm'
import Header from './components/header/Header'
import LargeSectionTextAndImage from './components/large-section-text-and-image/LargeSectionTextAndImage'
import Footer from '../../components/footer/Footer'
import image from "../../assets/images/dummySectionPhoto.png"
import { useMediaQuery, mobileQuery } from '../../helpers/mediaQuery'
// analytics
import {analyticsEventLog} from '../../App';

const NadaInternship = () => {
  let isMobile = useMediaQuery(mobileQuery)

  useEffect(() => {
    window.scrollTo(0, 0);
    analyticsEventLog("InternshipPage visited");
  }, []);

  return (
    <div style={styles.NadaInternshipWrapper}>
      <Header />
      <LargeSectionTextAndImage
        imageSource={image}
        title={"What you’ll be working on"}
        text={"You will mainly be working on an exciting startup project in its early stages. This startup is designed to transform our digital habits, moving from passive content consumption to active experience creation, and aims to revolutionize the software industry. This unique opportunity will be rewarded with equity-based compensation."}
      />
      <PositionDescriptions />
      <Benefits />
      {/* TODO: Application form error and succcess. */}
      <ApplicationForm />
      <div style={isMobile ? {marginTop:"60px", width:"100vw", boxSizing: "border-box"} : {marginTop: "120px", width: "100vw", boxSizing: "border-box"}}>
        <Footer showContactUs={false}/>
      </div>   
    </div>
  )
}

export default NadaInternship