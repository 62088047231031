import { spaceSurface, spaceGreen, spaceBackground } from "../../../../constants/Colors";
import './Card.css'

const styles = {
  closedCardWrapper: {
    backgroundColor: spaceSurface,
    width: '100%',
    minHeight: '148px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1%',
    overflow: 'hidden',
    zIndex: '13',
    cursor: 'pointer',
    padding: '0%',
  },
  closedCardWrapperMobile: {
    backgroundColor: spaceSurface,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1%',
    overflow: 'hidden',
    zIndex: '13',
    cursor: 'pointer',
    width: '100%',
    minHeight: '52px',
    // padding: '10px'
    marginBottom: '20px'
  },
  openedCardWrapper: {
    backgroundColor: spaceGreen,
    width: '100%',
    minHeight: '148px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    // padding: '5% 0%',
    animationName: 'slideInLeft, expandHeight, collapseHeight',
    transition: "min-height 0.15s ease-out",
    animationDuration: '0.2s, 0.2s, 5s',
    animationDelay: '0s, 0.3s, 0.3s',
    animationTimingFunction: 'ease-in,ease,ease',
    animationFillMode: 'forwards',
    zIndex: '14',
    cursor: 'pointer',
    margin: '0%',
  },
  openedCardWrapperMobile: {
    backgroundColor: spaceGreen,
    width: '100%',
    minHeight: '52px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    // padding: '5% 0%',
    animationName: 'slideInLeft, expandHeightMobile, collapseHeight',
    transition: "min-height 0.15s ease-out",
    animationDuration: '0.2s, 0.2s, 5s',
    animationDelay: '0s, 0.3s, 0.3s',
    animationTimingFunction: 'ease-in,ease,ease',
    animationFillMode: 'forwards',
    zIndex: '14',
    cursor: 'pointer',
    margin: '0%',
  },
  contentWrapper: {
    width: '100%'
  },
  cardTextWrapper: {
    width: '25%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '6.25%'
  },
  cardTextWrapperMobile: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: "10px 5px 10px 0px"
  },
  cardText: {
    letterSpacing: '0.025em',
    textTransform: 'uppercase',
    fontFeatureSettings: "'ss01' on, 'ss05' on, 'ss02' on",
    color: 'rgba(255, 255, 255, 0.9)',
    fontSize: '1em',
    fontFamily: 'SpaceGrotesk-Medium',
    fontStyle: 'normal',
    fontWeight: '500',
  },
  blink: {
    marginRight: '2%'
  },
  cardWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardWrapperMobile: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',  
    boxSizing: "border-box",
    padding: "0 20px"
  },
  cardArrowWrapper: {
    width: '3%',
    height: '13.5%',
    marginRight: '5%',
    backgroundColor: 'transparent',
    border: 'none',
    zIndex: '14'
  },
  cardArrowWrapperMobile: {
    width: '10%',
    // height: '13.5%',
    // marginRight: '5%',
    backgroundColor: 'transparent',
    border: 'none',
    zIndex: '14'
  },
  arrow: {
    width: '100%',
    height: '100%',
    cursor: 'pointer'
  },
  clickedCardWrapper: {
    width: '53%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: '6.25%'
  },
  clickedCardWrapperMobile: {
    width: '53%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // marginLeft: '6.25%'
  },
  cardDescription: {
    fontSize: '1em',
    fontStyle: 'normal',
    fontWeight: '500',
    fontFamily: 'Inter',
    color: spaceBackground,
    lineHeight: '150%',
    letterSpacing: '-0.025em',
    width: '54%',
    marginLeft: '6.25%',
    display: 'block'
  },
  cardDescriptionMobile: {
    fontSize: "16px",
    fontStyle: 'normal',
    fontWeight: '500',
    fontFamily: 'Inter',
    color: spaceBackground,
    lineHeight: '150%',
    letterSpacing: '-0.025em',
    // marginLeft: '6.25%',
    display: 'block',
    width: '80%',
    paddingLeft: '20px'
  },
  clickedTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '2%'
  },
  clickedCardTitle: {
    letterSpacing: '0.025em',
    textTransform: 'uppercase',
    fontSize: '1em',
    fontFamily: 'SpaceGrotesk-Medium',
    fontStyle: 'normal',
    fontWeight: '500',
    color: spaceBackground,
    lineHeight: '100%',
    fontFeatureSettings: "'ss01' on, 'ss05' on, 'ss02' on",
    marginLeft: '10%',
  },
};

export default styles;