const PositionsDescriptions = [
  {
    title: "Developers",
    description: "Short Description For developers we are offering a full-stack learning experience where the goal is to cover a wide variety of tasks and technologies in order to build them into well-rounded developers.",
    technologies: "Primary technologies and tools: React, React Native, Node.js, Firebase, Git  DevAzure,  NoSql, Notion",
    compensation: "Compensation Structure: The first month is a period of uncompensated skill-building and exploration. The second and third month, compensation can vary depending on individual performance and project specifics. Cash compensation is a possibility, while equity compensation, allowing interns to gain shares in our innovative social network startup, is highly probable.",
    hours: "Hours: Minimum 20 hours per week."
  },
  {
    title: "Managers / Scrum Masters",
    description: "Short Description: The manager's responsibilities are going to be analyzing the projects, communicating with project owners, creating tasks, distributing tasks to the team and monitoring the team's progress, and adjusting in order to achieve the project goals.",
    technologies: "Primary technologies: Notion, Git",
    compensation: "Compensation Structure: The initial month is dedicated to unpaid skill acquisition and industry exposure. In the following two months, compensation may be offered based on individual performance and project involvement, with equity compensation, providing a stake in our groundbreaking social network startup, being a significant possibility.",
    hours: "Hours: Minimum 5 hours per week."
  },
  {
    title: "Marketers",
    description: "As a marketer, you will be working on attracting users as the key R.O.I. of marketing efforts. It is an analytical and creative position where you will be having room to experiment by working on creative ideas and analyse the impact and conversation rates of those ideas.",
    technologies: "Primary Technologies: Notion, Social Media Marketing Tools, Google Analytics, Image Editing Tools, Ai Text Image and Video Generating Tools",
    compensation: "Compensation Structure: The initial month is dedicated to unpaid skill acquisition and industry exposure. In the following two months, compensation may be offered based on individual performance and project involvement, with equity compensation, providing a stake in our groundbreaking social network startup, being a significant possibility.",
    hours: "Hours: Minimum 5 hours per week."
  },
  {
    title: "Content Creators & Art Directors",
    description: "The art director will be working on projects, on content, and app features, providing ideas and general direction. The goal is improving the artistic quality of the user experience of MapToDo or creating eye-capturing experiences that we can convert into marketing and users. Both art directors and content creators are going to be consulting on the type of features that can have a virality to them for them to be a part of MapTo and generate content that is related to traveling, enjoying, and taking advantage of one’s own environment.",
    compensation: "Compensation: The initial month is dedicated to unpaid skill acquisition and industry exposure. In the following two months, compensation may be offered based on individual performance and project involvement, with equity compensation, providing a stake in our groundbreaking social network startup, being a significant possibility.",
    hours: "Hours: Minimum 5 hours per week."
  },
  {
    title: "Quality Assurance Developers",
    description: "As a Quality Assurance Developer intern, you will work closely with the development team to identify, prioritize, and address software defects. You will create and execute test plans, report bugs, and ensure that the final product meets quality standards and user expectations.",
    compensation: "Compensation Structure: The first month is a period of uncompensated skill-building and exploration. In the second and third month, compensation can vary depending on individual performance and project specifics. Cash compensation is a possibility, while equity compensation, allowing interns to gain shares in our innovative social network startup, is highly probable.",
    hours: "Hours: Minimum 10 hours per week."
  },
  {
    title: "Designers",
    description: "As a Designer intern, you will contribute to the development of visually appealing and user-friendly interfaces for web and mobile applications. You will collaborate with the development and marketing teams to create wireframes, mockups, and prototypes, ensuring a cohesive and consistent user experience across all platforms.",
    compensation: "Compensation Structure: The first month is a period of uncompensated skill-building and exploration. In the second and third month, compensation can vary depending on individual performance and project specifics. Cash compensation is a possibility, while equity compensation, allowing interns to gain shares in our innovative social network startup, is highly probable.",
    hours: "Hours: Minimum 15 hours per week."
  },
  {
    title: "Sales",
    description: "As a Sales intern, you will support the company's sales efforts by identifying and reaching out to potential clients, presenting product offerings, and maintaining relationships with existing customers. You will also assist in analyzing sales data to identify trends and opportunities for growth.",
    compensation: "Compensation: The initial month of the internship operates on a commission-based compensation structure, incentivizing your efforts. Following this, during the second and third months, standout performers will be offered a fixed cash compensation in addition to a commission on all sales.",
    hours: "Hours: Minimum 10 hours per week."
  }
];

export default PositionsDescriptions ;