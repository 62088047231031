import Title from "../../../../components/title/Title";
import DescriptionText from "../../../../components/description-text/DescriptionText";
import styles from "./Style_Info";
import "./Info.css";
import { useMediaQuery, mobileQuery } from "../../../../helpers/mediaQuery";

const Info = ({name, text, buttonText, onButtonClick}) => {
    let isMobile=useMediaQuery(mobileQuery);
    return(
        <div style={isMobile ? {width: "100%"} : {marginRight: "20px", width: "25%"}}>
            <Title small customStyles={isMobile? styles.nameMobile : styles.name}>
               {name}
            </Title> 
            {
              buttonText ? 
              <button onClick={onButtonClick} style={styles.buttonWrapper}>{buttonText}<span id="blinkInfo"/></button> :
              <DescriptionText customStyles={isMobile? styles.textMobile : styles.text}>
                {text}
              </DescriptionText>
            }
            
        </div>
        
    )
}

export default Info;