import styles from "./LargeSectionTextAndImage_Style.js";
import { useMediaQuery, mobileQuery } from "../../../../helpers/mediaQuery"

const LargeSectionTextAndImage = ({imageSource, title, text}) => {
  let isMobile = useMediaQuery(mobileQuery);

  return(
    <div style={isMobile ? styles.wrapperMobile : styles.wrapper}>
      <div style={isMobile ? styles.descriptionWrapperMobile : styles.descriptionWrapper}>
        <div style={isMobile ? styles.titleMobile : styles.title}>
          {title}
        </div>
        <div style={isMobile ? styles.textMobile : styles.text}>
          {text}
        </div>
      </div>
    </div>
  )
}

export default LargeSectionTextAndImage;