import Wrapper from "../components/wrapper/Wrapper";
import { projects } from "../../landing/Projects";
import Content from "./content/Content";
import InternshipProject from "../projects/InternshipProject"
import {useState} from "react"

const ProjectPage = () => {
    const project = InternshipProject;
    const nextUp = projects[1];


    const [title, setTitle] = useState(10);

    return(
        <Wrapper
            title={title}
            // title={project.title}
            description={project.description}
            industries={project.categories ? project.categories[0]+", "+project.categories[1] : null}
            partners={project.partners}
            launchDate={"24th October 2021"}
            projectImage={project.thumbnailImage}
            nextUp={nextUp}
            content={<Content/>}
        />
    )
}

export default ProjectPage;