import { stars } from "./Stars";
import BaseStar from "./BaseStar";
import { useMediaQuery, mobileQuery } from "../../../../helpers/mediaQuery";

/**
 ** Represents wrapper of LandingPage component as mapped stars array. Each element is displayed as BaseStar component. 
 * @param {*} children - components inside wrapper (LandingPage component). 
 */
const StarShower = ({children})=> {
    let isMobile = useMediaQuery(mobileQuery);
    return <div style={
                isMobile ? 
                {position:"relative", overflowX:"hidden"} : 
                {position:"relative"}}
            >
            {stars.map((star, index)=>{
        return <BaseStar key={index} y={star[0]} x={star[1]} delay={star[2]} duration={star[3]}/>
    })}
            {children}
    </div>   

}


export default StarShower;